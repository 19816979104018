import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiEpgButtonDirective, UiEpgButtonIconDirective } from '@epg-apps/ui-button';

@Component({
  standalone: true,
  selector: 'epg-toolbar',
  imports: [CommonModule, 
    UiEpgButtonDirective, 
    UiEpgButtonIconDirective, 
  ],
  templateUrl: './epg-toolbar.component.html',
  styleUrl: './epg-toolbar.component.scss',
})
export class EpgToolbarComponent {}
