<div class="navbar">
    <div class="header">
        <div class="content">
            <div class="left">
                <div class="logo"></div>
            </div>
            
                <div class="mid">
    
                    <!-- <button epg-button epg-button-icon shape="text" class="portal-bar-selector p-button p-button-text portal-bar-selector-button">
                        <span class="fa-regular fa-grid"></span>
                        <span class>CDH</span>
                        <span></span>
                        <span class="fa-solid fa-chevron-down"></span>
                    </button>
    
                    <button epg-button epg-button-icon shape="text" class="portal-bar-selector p-button p-button-text portal-bar-selector-button">
                        <span class="fa-regular fa-folder-user"></span>
                        <span class>1234</span>
                        <p class="sub-item">Customer name</p>
                        <span></span>
                        <span class="fa-solid fa-chevron-down"></span>
                    </button> -->
                </div>
           
            <div class="right">
                <!-- <button epg-button epg-button-icon shape="text" class="portal-button">
                    <span class="fa-solid fa-download epg-icon"></span>
                </button>
                <button epg-button epg-button-icon shape="text" class="portal-button">
                    <span class="fa-regular fa-house epg-icon"></span>
                </button>
                <button epg-button epg-button-icon shape="text" class="portal-button">
                    <span class="fa-regular fa-question epg-icon"></span>
                </button> -->
                <button epg-button epg-button-icon shape="text" class="portal-button">
                    <span class="fa-regular fa-user epg-icon"></span>
                </button>
    
            </div>
            
        </div>
    </div>
    </div>
    