import { Component, computed, inject, Input, signal, ViewEncapsulation } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { UxlibCustomerbarModule } from '@partena/uxlib-customerbar';
import { ActivatedRoute, RouterModule, RouterOutlet } from '@angular/router';

// Translations
import * as commonEnUS from  '../../../../../../../../node_modules/@partena/uxlib-core/translations/en-US.json';
import * as commonFrFR from  '../../../../../../../../node_modules/@partena/uxlib-core/translations/fr-FR.json';
import * as commonNlBE from  '../../../../../../../../node_modules/@partena/uxlib-core/translations/nl-BE.json';

// import * as commonEnUS from '@partena/uxlib-core/translations/en-US.json';
// import * as commonFrFR from '@partena/uxlib-core/translations/fr-FR.json';
// import * as commonNlBE from '@partena/uxlib-core/translations/nl-BE.json';

import {
  APP_MENU_BACK_TO_PREVIOUS_TITLE_TOKEN,
  APP_MENU_CONFIG_TOKEN,
  APP_NAME_TOKEN,
  APP_TOOLBAR_CONFIG_TOKEN,
  PORTAL_BAR_TITLE_TOKEN,
} from '../../epg-modern-layout-config.constant';
import { NavigationService } from '../../epg-modern-layout-navigation.service';
import { routePageAnimationLeftToRightAnimation } from './route-animation';
import { TranslateService } from '@ngx-translate/core';
import { PortalBarCompanyModel } from '@partena/uxlib-core';
import { TranslationLocal } from '@epg-apps/util-translations';
import { UiColorToolbarComponent } from '@epg-apps/ui-color-toolbar';
import { EpgDownloadService, EpgDownloadCenterService } from '@epg-apps/epg-download';
import { HttpEventType } from '@angular/common/http';
import { EpgToolbarComponent } from '@epg-apps/epg-toolbar';



@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, UxlibCustomerbarModule, EpgToolbarComponent],
  templateUrl: './epg-modern-layout.component.html',
  styleUrl: './epg-modern-layout.component.scss',
  animations: [routePageAnimationLeftToRightAnimation],
  encapsulation: ViewEncapsulation.None
})
export class EpgModernLayoutComponent {
  @Input() navigateToPreviousPage?: string;
  @Input() navigateToPreviousLabel?: string;

  // Toolbar Config
  toolbarConfig = inject(APP_TOOLBAR_CONFIG_TOKEN, {optional: true});
  // Portalbar Config
  portalBarTitle = inject(PORTAL_BAR_TITLE_TOKEN, { optional: true }) || '' ;
  // Injectable application name
  applicationName = inject(APP_NAME_TOKEN, { optional: true });


  // TODO: Create a user profile service to provide necessary info
  // eg:
  // userProfile = signal({
  //   fullName: 'Smith, Robert',
  //   email: 'robert.smith@partena.be'
  // });


  private navigationService = inject(NavigationService);
  private activatedRouter = inject(ActivatedRoute);
  private translateService = inject(TranslateService);
  private downloadService = inject(EpgDownloadService);
  private downloadCenterService = inject(EpgDownloadCenterService);
  private location = inject(Location);

  // TODO:  move this to the download center logic
  // private downloadState = signal({
  //   downloading: false,
  //   progress: 0,
  //   error: null,
  //   isComplete: false
  // });

  public isDownloading = computed(() => this.downloadCenterService.downloadState().downloading);
  public downloadCompleted = computed(() => this.downloadCenterService.downloadState().isCompleted);

  // selectedSubMenu = this.navigationService.selectedSubMenu();

  public backLinkToParentTitle = inject(APP_MENU_BACK_TO_PREVIOUS_TITLE_TOKEN, {
    optional: true,
  });

  public applicationMenuBar = inject(APP_MENU_CONFIG_TOKEN, {
    optional: true,
  }) || [{ path: '', label: this.applicationName, alias: this.applicationName }];
  pageLinkTitle: string | null  = this.navigateToPreviousLabel ??  'Go back to previous page';

  navigateBack() {
    // return this.navigationService.back();
    return this.location.back();
  }

  ngOnInit() {
    this.translateService.setTranslation(TranslationLocal.EN, Object.assign({}, commonEnUS));
    this.translateService.setTranslation(TranslationLocal.FR, Object.assign({}, commonFrFR));
    this.translateService.setTranslation(TranslationLocal.NL, Object.assign({}, commonNlBE));

    this.translateService.setDefaultLang(TranslationLocal.EN);
    this.translateService.use(TranslationLocal.EN);
  }
  
  onDownload() {
    // this.downloadState.update( state => ({...state,downloading: true, progress: 0, error: null}));

    this.downloadCenterService.downloadState.update( state => ({...state,downloading: true, progress: 0, error: null}));
    this.downloadService.download();

    // this.downloadService.download().subscribe( event => {
    //   switch(event.type) {
    //     case HttpEventType.DownloadProgress:
    //       // this.downloadState.update( state => ({...state,downloading: true, progress: 0, error: null}));
    //       this.downloadCenterService.downloadState.update( state => ({...state,downloading: true, progress: 0, error: null}));
    //       console.log('Download ' + event.loaded + ' out of ' + event.total + ' bytes');
    //       break;
    //     case HttpEventType.Response:
    //       // this.downloadState.update( state => ({...state,downloading: false, progress: 100, error: null, isComplete: true}));
    //       this.downloadCenterService.downloadState.update( state => ({...state,downloading: false, progress: 0, error: null, isCompleted: true}));
    //       console.log('Finished Downloading!');
    //       break;
    //   }
    // });
  }

  openDownloadedFile() {
    this.downloadCenterService.downloadState.update( state => ({...state,downloading: false, progress: 0, error: null, isComplete: false}));
  }

  private logRouteData(route: ActivatedRoute, parentPath: string = ''): void {
    const path = parentPath + '/' + route.routeConfig?.path; // Construct full path

    // Log data for the current route
    if(route.snapshot.data){
        console.log(`Route: ${path}, Data:`, route.snapshot.data);
    }

    // Recursively log data for child routes
    route.children.forEach(childRoute => {
      this.logRouteData(childRoute, path);
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  showBackPageLink () {
    return this.navigateToPreviousPage || this.activatedRouter.snapshot.firstChild?.data['navigateToPreviousPage'];
  }
}
