import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { EnvironmentInjector, inject, runInInjectionContext } from '@angular/core';
import { EpgStatusDialogService } from '@epg-apps/epg-status-dialog';
import { catchError, throwError } from 'rxjs';

export const epgHttpErrorInterceptor: HttpInterceptorFn = (req, next) => {  
  const environmentInjector = inject(EnvironmentInjector);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {

      switch( error.status) {
        case 401:
          // TODO: when IAM or unauthorization has been fixed, replace this with the correct setup or redirect
          console.error('Unauthorized error', error);
          break;
        default:
          showHttpErrorStatusDialog(environmentInjector, error);
      }

      return throwError(() => error);
    })
  );
};

export function showHttpErrorStatusDialog(environmentInjector: EnvironmentInjector, error: HttpErrorResponse) {
  runInInjectionContext(environmentInjector, () => {
    inject(EpgStatusDialogService).open({
      title: error.statusText,
      statusCode: error.status,
      htmlContent: '',
      dismissLabel: 'OK'
    });
  });
}

